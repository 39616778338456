import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 40 42" style={{ width: "12px", height: "12px" }} {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40 9.14087L32.9624 4.95661L27.0176 8.45474V4.16926L19.9909 0L12.9861 4.16926V8.49973L6.97204 4.95661L0 9.14462V17.4794L5.91203 21.0112L0 24.5431V32.8104L6.97204 37.0659L12.9132 33.549V37.8832L19.9545 42L27.0249 37.8832L27.0103 33.549L32.966 37.0659L40 32.8104V24.5431L34.0625 21.0112L40 17.4794V9.14087ZM32.9624 6.26513L38.3499 9.46706L26.4748 16.5008L23.415 14.6749L27.0176 12.5378V9.75951L32.9624 6.26513ZM14.0861 5.47402L19.4445 8.6647V15.0723L18.7342 14.6486L17.6341 13.9963L14.0861 11.8854V5.47402ZM25.9175 11.8854L21.2185 14.6711L20.5446 15.0723V8.6647L25.9175 5.47402V11.8854ZM19.9945 7.68238L14.6362 4.49545L19.9945 1.30477L25.3638 4.49545L19.9945 7.68238ZM6.97933 6.26513L12.9861 9.8045V12.5378L16.5413 14.6524L13.4633 16.4971L1.64648 9.46706L6.97933 6.26513ZM1.10008 16.827V10.4494L12.3668 17.1532L7.00847 20.3551L1.10008 16.827ZM6.42564 35.4162L1.10008 32.1655V25.8554L6.42564 29.0536V35.4162ZM1.64284 24.8693L13.4669 17.8056L18.8507 20.9513L6.97569 28.0712L1.64284 24.8693ZM19.4044 40.3765L14.0133 37.2271V32.8966L19.4044 29.7059V40.3765ZM19.4044 28.4012L7.52573 35.4312V29.0536L19.4044 21.9298V28.4012ZM14.567 17.1457L17.6377 15.3048L19.9909 16.707L22.3186 15.3273L25.3675 17.1457L19.9545 20.2951L14.567 17.1457ZM20.5045 40.3803V29.7059L25.9102 32.8966L25.9211 37.2271L20.5045 40.3803ZM32.4123 35.4349L20.5045 28.3974V21.9298L32.4123 29.0536V35.4349ZM38.8999 32.1655L33.5124 35.4237V29.0536L38.8999 25.8479V32.1655ZM38.3535 24.8693L32.9624 28.0712L21.0582 20.9513L26.4712 17.8056L38.3535 24.8693ZM38.8999 16.827L32.9624 20.3589L27.5749 17.1532L38.8999 10.4456V16.827Z"
        fill="#0CC2FF"
      />
    </Svg>
  );
};

export default Icon;
