import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 27" fill="none" {...props}>
      <path
        d="M12.372 17.907h.011c2.261-.002 4.078-.539 5.345-1.499 1.281-.97 1.96-2.348 1.96-3.907 0-1.5-.677-2.583-1.444-3.315a6.393 6.393 0 00-1.76-1.168c.107-.322.243-.753.364-1.199.158-.578.31-1.252.31-1.744 0-.584-.128-1.167-.472-1.617-.361-.476-.906-.731-1.565-.731-.513 0-.948.188-1.29.513-.326.31-.544.725-.696 1.16-.27.774-.373 1.751-.401 2.727h-.712c-.028-.976-.131-1.953-.4-2.727-.152-.435-.371-.85-.698-1.16a1.824 1.824 0 00-1.289-.513c-.659 0-1.203.255-1.565.73-.344.451-.472 1.034-.472 1.618 0 .492.153 1.166.31 1.744.121.446.257.877.364 1.2-.464.209-1.145.58-1.76 1.167C5.745 9.918 5.067 11 5.067 12.5c0 1.559.68 2.936 1.961 3.907 1.267.96 3.084 1.497 5.344 1.499z"
        fill="url(#paint0_linear_2863_25822)"
        stroke="url(#paint1_linear_2863_25822)"
        strokeWidth={1.21283}
      />
      <g clipPath="url(#clip0_2863_25822)">
        <path
          transform="rotate(45 12.435 3.66)"
          fill="#000"
          stroke="url(#paint2_linear_2863_25822)"
          strokeWidth={1.34759}
          d="M12.4348 3.6607H27.2583V18.484199999999998H12.4348z"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.362 18.289c-2.024-.002-3.654-.482-4.793-1.345-1.152-.874-1.764-2.113-1.764-3.515 0-1.352.61-2.326 1.3-2.985A5.732 5.732 0 018.66 9.408c-.094-.286-.212-.66-.317-1.046-.14-.516-.278-1.122-.278-1.566 0-.526.116-1.054.428-1.464.33-.433.827-.665 1.425-.665.467 0 .863.171 1.174.467.296.282.494.658.63 1.049.24.688.333 1.551.36 2.413h.571c.027-.862.12-1.725.36-2.413.136-.391.333-.767.63-1.05.31-.295.707-.466 1.174-.466.598 0 1.094.232 1.424.665.313.41.428.938.428 1.464 0 .444-.137 1.05-.278 1.566-.105.386-.222.76-.316 1.046.416.19 1.013.52 1.554 1.036.69.66 1.3 1.633 1.3 2.985 0 1.402-.611 2.641-1.764 3.515-1.139.863-2.769 1.343-4.793 1.345h-.01z"
        fill="#000"
      />
      <path
        d="M9.918 5.167c-.876 0-1.279.652-1.279 1.555 0 .717.469 2.154.66 2.713a.251.251 0 01-.148.313c-.701.276-2.771 1.289-2.771 3.607 0 2.442 2.103 4.283 5.983 4.286H12.372c3.88-.003 5.983-1.844 5.983-4.286 0-2.319-2.07-3.33-2.772-3.607a.251.251 0 01-.148-.313c.192-.559.66-1.996.66-2.713 0-.903-.403-1.555-1.278-1.555-1.26 0-1.574 1.783-1.596 3.698a.234.234 0 01-.233.231h-1.241a.234.234 0 01-.233-.231c-.023-1.915-.337-3.698-1.596-3.698z"
        fill="#fff"
      />
      <path
        d="M12.372 16.858c-2.85 0-5.988-1.524-5.992-3.499v.01c0 2.443 2.107 4.286 5.992 4.286s5.992-1.843 5.992-4.287v-.009c-.005 1.975-3.142 3.5-5.992 3.5z"
        fill="#E5E5E5"
      />
      <path
        d="M10.788 12.753c0 .666-.315 1.014-.704 1.014-.389 0-.704-.348-.704-1.014 0-.667.315-1.014.704-1.014.389 0 .704.347.704 1.014zM15.363 12.753c0 .666-.315 1.014-.703 1.014-.39 0-.704-.348-.704-1.014 0-.667.315-1.014.704-1.014.388 0 .703.347.703 1.014z"
        fill="#000"
      />
      <path d="M15.13 19.333H9.74L12.567 22l2.563-2.667z" fill="#FFE70F" />
      <defs>
        <linearGradient
          id="paint0_linear_2863_25822"
          x1={10.4332}
          y1={2.00002}
          x2={15.7348}
          y2={12.0469}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#57F1FF" />
          <stop offset={1} stopColor="#61CCFE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2863_25822"
          x1={6.0573}
          y1={3.7328}
          x2={19.2144}
          y2={16.3639}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54FDFF" />
          <stop offset={1} stopColor="#69A9FD" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2863_25822"
          x1={11.4819}
          y1={0.774107}
          x2={28.1575}
          y2={6.01502}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54FDFF" />
          <stop offset={1} stopColor="#69A9FD" />
        </linearGradient>
        <clipPath id="clip0_2863_25822">
          <path fill="#fff" transform="translate(1 8.752)" d="M0 0H22.9091V17.5187H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
